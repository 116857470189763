export const actionType = {
  ViewProfile_Type: "ViewProfile_Type",
  ViewOrderDetails_Type: "ViewOrderDetails_Type",
  PatchUserDetails_Type: "PatchUserDetails_Type",
  DeleteUserAddress_Type: "DeleteUserAddress_Type",
  ResetPasswordPatchReducer_Type: "ResetPasswordPatchReducer_Type",
  PostPincodesAvailabilityDispatch_Type:
    "PostPincodesAvailabilityDispatch_Type",
  PostPincodesDeliveredDispatch_Type: "PostPincodesDeliveredDispatch_Type",
  PostPickupAddressDispatch_Type: "PostPickupAddressDispatch_Type",
  PatchPickupAddressDispatch_Type: "PatchPickupAddressDispatch_Type",
  PostDeliveryAddressDispatch_Type: "PostDeliveryAddressDispatch_Type",
  GetShipmentDetailsDispatch_Type: "GetShipmentDetailsDispatch_Type",
  PostShipmentDetailsDispatch_Type: "PostShipmentDetailsDispatch_Type",
  GetAdminDashboardViewOrderDispatch_Type:
    "GetAdminDashboardViewOrderDispatch_Type",
  PostAdminDashboardTransactionDispatch_Type:
    "PostAdminDashboardTransactionDispatch_Type",
  PostAdminDashboardShippingMatrixDispatch_Type:
    "PostAdminDashboardShippingMatrixDispatch_Type",
  GetAdminOrderIntransitDispatch_Type: "GetAdminOrderIntransitDispatch_Type",
  GetAdminOrderDeliveredDispatch_Type: "GetAdminOrderDeliveredDispatch_Type",
  GetAdminOutForDeliveryDispatch_Type: "GetAdminOutForDeliveryDispatch_Type",
  GetAdminOrderPendingDispatch_Type: "GetAdminOrderPendingDispatch_Type",
  PostViewOrderDetailsDispatch_Type: "PostViewOrderDetailsDispatch_Type",
  GetAdminOrderReturnDispatch_Type: "GetAdminOrderReturnDispatch_Type",
  GetAdminOrderRTODeliveredDispatch_Type:
    "GetAdminOrderRTODeliveredDispatch_Type",
  GetAdminOrderSummaryDispatch_Type: "GetAdminOrderSummaryDispatch_Type",
  PostAddRemarkDispatch_Type: "PostAddRemarkDispatch_Type",
  GetAdminOrderBookedDispatch_Type: "GetAdminOrderBookedDispatch_Type",
  GetAdminOrderPickedUpDispatch_Type: "GetAdminOrderPickedUpDispatch_Type",
  GetAdminOrderReadyForPickupDispatch_Type:
    "GetAdminOrderReadyForPickupDispatch_Type",
  GetAdminOrderReceivedAtHubDispatch_Type:
    "GetAdminOrderReceivedAtHubDispatch_Type",
  PostAdminOrderFilterationDispatch_Type:
    "PostAdminOrderFilterationDispatch_Type",
  ToggleSideBar_Type: "ToggleSideBar_Type",
  OrderPageBookNavigate_Type: "OrderPageBookNavigate_Type",
  OrderPageBoookNavigate_Type: "OrderPageBoookNavigate_Type",
  GetAdminOrderCustomerDispatch_Type: "GetAdminOrderCustomerDispatch_Type",
  PatchAdminOrderEditDispatch_Type: "PatchAdminOrderEditDispatch_Type",
  GetAdminOrderCallBuyerDispatch_Type: " GetAdminOrderCallBuyerDispatch_Type",
  GetAdminOrderGenerateOrderIdDispatch_Type:
    "GetAdminOrderGenerateOrderIdDispatch_Type",
  PostAdminOrderPaymentCalDispatch_Type:
    "PostAdminOrderPaymentCalDispatch_Type",
  PostAdminOrderAddShipmentDispatch_Type:
    "PostAdminOrderAddShipmentDispatch_Type",
  PostViewAdminOrderDispatch_Type: "PostViewAdminOrderDispatch_Type",
  GetDeliveryPriceDetailDispatch_Type: "GetDeliveryPriceDetailDispatch_Type",
  GetAdminOrderPaymentOrderDispatch_Type:
    "GetAdminOrderPaymentOrderDispatch_Type",
  PostAdminOrderPaymentOrderDispatch_Type:
    "PostAdminOrderPaymentOrderDispatch_Type",
  PostAdminOrderEwayBillDispatch_Type: "PostAdminOrderEwayBillDispatch_Type",
  GetAdminOrderCloneOrderDispatch_Type: "GetAdminOrderCloneOrderDispatch_Type",
  PostAdminOrderActionDispatch_Type: "PostAdminOrderActionDispatch_Type",
  DeleteAdminPendingOrderActionDispatch_Type:
    "DeleteAdminPendingOrderActionDispatch_Type",
  PostAdminSettingAddEmployeeDispatch_Type:
    "PostAdminSettingAddEmployeeDispatch_Type",
  PostAdminSettingAddDeliveryboyDispatch_Type:
    "PostAdminSettingAddDeliveryboyDispatch_Type",
  PostAdminSettingDeliveryPartnerDispatch_Type:
    "PostAdminSettingDeliveryPartnerDispatch_Type",
  PostAssignDeliveryBoyPartnerDispatch_Type:
    "PostAssignDeliveryBoyPartnerDispatch_Type",
  GetSettingDeliveryboyInfoDispatch_Type:
    "GetSettingDeliveryboyInfoDispatch_Type",
  GetAdminSettingDeliveryPartnerDispatch_Type:
    "GetAdminSettingDeliveryPartnerDispatch_Type",
  PatchAdminSettingDeliveryPartnerDispatch_Type:
    "PatchAdminSettingDeliveryPartnerDispatch_Type",
  DeleteAdminSettingDeliverypartnerDispatch_Type:
    "DeleteAdminSettingDeliverypartnerDispatch_Type",
  GetCategoryDetailsDispatch_Type: "GetCategoryDetailsDispatch_Type",
  GetSettingViewPermissionDispatch_Type:
    "GetSettingViewPermissionDispatch_Type",
  GetSettingEmployeeInfoDispatch_Type: "GetSettingEmployeeInfoDispatch_Type",
  PostAdminSettingAddCategoryDispatch_Type:
    "PostAdminSettingAddCategoryDispatch_Type",
  GetSettingUserInfoDispatch_Type: "GetSettingUserInfoDispatch_Type",
  GetUserOrderIdListDispatch_Type: "GetUserOrderIdListDispatch_Type",
  PostAddAmountDebitDispatch_Type: "PostAddAmountDebitDispatch_Type",
  DeleteAdminSettingDeleteUserDispatch_Type:
    "DeleteAdminSettingDeleteUserDispatch_Type",
  PatchEditUserPermissionDispatch_Type: "PatchEditUserPermissionDispatch_Type",
  GetSettingViewB2bFeedbackDispatch_Type:
    "GetSettingViewB2bFeedbackDispatch_Type",
  GetSettingViewB2bCloseFeedbackDispatch_Type:
    "GetSettingViewB2bCloseFeedbackDispatch_Type",
  GetSettingViewB2cFeedbackDispatch_Type:
    "GetSettingViewB2cFeedbackDispatch_Type",
  GetSettingViewB2cCloseFeedbackDispatch_Type:
    "GetSettingViewB2cCloseFeedbackDispatch_Type",
  DeleteSettingDismissTicketDispatch_Type:
    "DeleteSettingDismissTicketDispatch_Type",
  PatchEditCategoryDetailsDispatch_Type:
    "PatchEditCategoryDetailsDispatch_Type",
  DeleteCategoryDetailsDispatch_Type: "DeleteCategoryDetailsDispatch_Type",
  GetAdminProfileDispatch_Type: "GetAdminProfileDispatch_Type",
  PatchEditProfileDispatch_Type: "PatchEditProfileDispatch_Type",
  PatchAdminEditProfileDispatch_Type: "PatchAdminEditProfileDispatch_Type",
  PostAdminOrderCsvFileDispatch_Type: "PostAdminOrderCsvFileDispatch_Type",
  GetAdminCloneOrderDispatch_Type: "GetAdminCloneOrderDispatch_Type",
  PostOrderDownloadInvoiceFileDispatch_Type:
    "PostOrderDownloadInvoiceFileDispatch_Type",
  GetOrderDownloadInvoiceDispatch_Type: "GetOrderDownloadInvoiceDispatch_Type",
  PostOrderDownloadLabelGenerationFileDispatch_Type:
    "PostOrderDownloadLabelGenerationFileDispatch_Type",
  GetOrderDownloadLabelGenerationDispatch_Type:
    "GetOrderDownloadLabelGenerationDispatch_Type",
  DeleteAdminOrderDispatch_Type: "DeleteAdminOrderDispatch_Type",
  PatchEditEmployeeDispatch_Type: "PatchEditEmployeeDispatch_Type",
  PatchEditDeliveryboyDispatch_Type: "PatchEditDeliveryboyDispatch_Type",
  GetBillingInvoiceDetailDispatch_Type: "GetBillingInvoiceDetailDispatch_Type",
  GetBillingAmountCountDispatch_Type: "GetBillingAmountCountDispatch_Type",
  GetDashboardNotificationDispatch_Type:
    "GetDashboardNotificationDispatch_Type",
  PostDeliveryBoyNotificationDispatch_Type:
    "PostDeliveryBoyNotificationDispatch_Type",
  GetDeliveryBoyNotificationDispatch_Type:
    "GetDeliveryBoyNotificationDispatch_Type",
  GetAdminRemarkNotificationDispatch_Type:
    "GetAdminRemarkNotificationDispatch_Type",
  PostRemarkNotificationDispatch_Type: "PostRemarkNotificationDispatch_Type",
  PostAddOrderTagDispatch_Type: "PostAddOrderTagDispatch_Type",
  PostUploadFileDispatch_Type: "PostUploadFileDispatch_Type",
  PostBulkUploadFileDispatch_Type: "PostBulkUploadFileDispatch_Type",
  PostDashboardRevenueDispatch_Type: "PostDashboardRevenueDispatch_Type",
  PostDashboardViewOrderDispatch_Type: "PostDashboardViewOrderDispatch_Type",
  GetCodRemittanceDispatch_Type: "GetCodRemittanceDispatch_Type",
  GetCodRemittanceBillingAmountDispatch_Type:
    "GetCodRemittanceBillingAmountDispatch_Type",
  GetB2bCompanyInfoDispatch_Type: "GetB2bCompanyInfoDispatch_Type",
  PostUploadBillRemittanceFileDispatch_Type:
    "PostUploadBillRemittanceFileDispatch_Type",
  PostUploadTariffFileDispatch_Type: "PostUploadTariffFileDispatch_Type",
  PostUploadInsuranceFileDispatch_Type: "PostUploadInsuranceFileDispatch_Type",
  PostUploadPackagingFileDispatch_Type: "PostUploadPackagingFileDispatch_Type",
  GetWalletHistoryDispatch_Type: "GetWalletHistoryDispatch_Type",
  GetWalletBalanceDispatch_Type: "GetWalletBalanceDispatch_Type",
  PostWalletAddMoneyDispatch_Type: "PostWalletAddMoneyDispatch_Type",
  PostPincodeUploadFileDispatch_Type: "PostPincodeUploadFileDispatch_Type",
  PostDebitBalanceDispatch_Type: "PostDebitBalance_Dispatch_Type",
  PostTrackingOrderDetailsDispatch_Type:
    "PostTrackingOrderDetailsDispatch_Type",
  PostCreateTicketDispatch_Type: "PostCreateTicketDispatch_Type",
  PostTicketDetailDispatch_Type: "PostTicketDetailDispatch_Type",
  DeleteSupportTicketDispatch_Type: "DeleteSupportTicketDispatch_Type",
  PostTicketAddCommentDetailDispatch_Type:
    "PostTicketAddCommentDetailDispatch_Type",
  PostBillingCodRemittanceCountDispatch_Type:
    "PostBillingCodRemittanceCountDispatch_Type",
  PostBillingCodRemittanceDetailsDispatch_Type:
    "PostBillingCodRemittanceDetailsDispatch_Type",
  HeaderToggleClassAdd_Type: "HeaderToggleClassAdd_Type",
  PostCreateFeedbackDispatch_Type: "PostCreateFeedbackDispatch_Type",
  PatchTrackDetailsDispatch_Type: "PatchTrackDetailsDispatch_Type",
  GetCustomerOrderDetailDispatch_Type: "GetCustomerOrderDetailDispatch_Type",
  PostRaiseContactUSDispatch_Type: "PostRaiseContactUSDispatch_Type",
  PostOrderTrackDispatch_Type: "PostOrderTrackDispatch_Type",
  PostTrackDispatch_Type: "PostTrackDispatch_Type",
  PostCompanyFileDispatch_Type: "PostCompanyFileDispatch_Type",
  PostGetFeedbackDispatch_Type: "PostGetFeedbackDispatch_Type",
  PostKYCdetailDispatch_Type: "PostKYCdetailDispatch_Type",
  PostClearNotificationDispatch_Type: "PostClearNotificationDispatch_Type",
  GetUserNotificationDispatch_Type: "GetUserNotificationDispatch_Type",
  ShipmentLoaderTrueFalse_Type: "ShipmentLoaderTrueFalse_Type",
  ToggleSideBarTrueFalse_Type: "ToggleSideBarTrueFalse_Type",
  GetAuthDetailsDispatch_Type: "GetAuthDetailsDispatch_Type",
  GetPermissionDispatch_Type: "GetPermissionDispatch_Type",
  BulkUploadDispatch_Type: "BulkUploadDispatch_Type",
  GetGoogleCityStateDispatch_Type: "GetGoogleCityStateDispatch_Type",
  PaymentPopupValue_Type: "PaymentPopupValue_Type",
  PostTransactionHistoryDispatch_Type: "PostTransactionHistoryDispatch_Type",
  GetCancelOrderDetailDispatch_Type: "GetCancelOrderDetailDispatch_Type",
  PostTrackingOtpDispatch_Type: "PostTrackingOtpDispatch_Type",
  GetAdminOrderRebookDispatch_Type: "GetAdminOrderRebookDispatch_Type",
  PostTrackLocationDetailsDispatch_Type:
    "PostTrackLocationDetailsDispatch_Type",
  OrderPagesLoaderTrueFalse_Type: "OrderPagesLoaderTrueFalse_Type",
  PostQrDetailsDispatch_Type: "PostQrDetailsDispatch_Type",
  PostPaymentApprovalDispatch_Type: "PostPaymentApprovalDispatch_Type",
  PostPaymentChatDispatch_Type: "PostPaymentChatDispatch_Type",
  PatchPaymentApprovalActionDispatch_Type:
    "PatchPaymentApprovalActionDispatch_Type",
  PostPaymentAddAmountDispatch_Type: "PostPaymentAddAmountDispatch_Type",
  PostBulkUploadFileDispatch_Type:"PostBulkUploadFile_Type",
  GetBulkUploadFileDispatch_Type:"GetBulkUploadFileDispatch_Type",
  PostCodeRemnitanceCsvDispatch_Type:"PostCodeRemnitanceCsvDispatch_Type",
  PostDownloadBulkOrderDispatch_Type:"PostDownloadBulkOrderDispatch_Type",
  PostDownloadRemmitenceDispatch_Type  : "PostDownloadRemmitenceDispatch_Type",
};
