import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, NavLink, useLocation, useParams } from "react-router-dom";
import Sidebar from "../Sidebar";
import Header from "../Header";
import upload from "../../Images/upload2.svg";
import rotateimg from "../../Images/icon1.svg";
import pause from "../../Images/pause.svg";
import {
    BulkUpload,
    PostUploadBulkFiles,
    PostDownloadBulkOrder
} from "../../Redux/action/ApiCollection";
import { toast } from "react-toastify";
import LodingSpiner from "../../Components/LodingSpiner";
import { PermissionData } from "../../Permission";

const UserDetail = () => {
    const dispatch = useDispatch();
    const route = useLocation();
    const navigate = useNavigate();

    const ToggleFunData = useSelector(
        (state) => state.ToggleSideBarReducer.ToggleSideBarData
    );
    const PostBulkFileData = useSelector(
        (state) =>
            state.PostUserBulkUploadReducer.PostUserDeatilsBulkUploadData?.data
    );
    const GetBulkUploadFileData = useSelector(
        (state) => state.GetBulkUploadFileReducer.GetBulkUploadFileData?.data
    );

    const BulkUploadData = useSelector(
        (state) => state.BulkUploadReducer.BulkUploadData?.data
    );
    const BulkUploadDataData = useSelector(
        (state) => state.BulkUploadReducer.BulkUploadData
    );
    const PostDownloadBulkOrderData = useSelector(
        (state) => state.PostDownloadBulkOrderReducer.PostDownloadBulkOrderData?.data
    );
    const OrderPagesLoaderTrueFalseData = useSelector(
        (state) =>
            state.OrderPagesLoaderTrueFalseReducer?.OrderPagesLoaderTrueFalseData
    );




    // console.log("BulkUploadData",PostDownloadBulkOrderData)

    const [bulkDetailsData, setBulkDetailsData] = useState("");
    const [message, setMessage] = useState("");
    const [showData, setShowData] = useState("");
    const [percentage, setPercentage] = useState(0);
    const [routeData, setRouteData] = useState("");
    const [taskBulkId, setTaskBulkId] = useState("");
    const [postTaskId, SetPostTaskId] = useState("")


    // useEffect(()=>{
    //     let payload = {
    //         taskId: postTaskId
    //     }
    //     dispatch(BulkUpload(payload));
    // },[])

    // console.log("routeData",routeData?.task_id ,postTaskId , taskBulkId)

    useEffect(() => {
        setRouteData(route?.state?.currentData)
        setTaskBulkId("")
        SetPostTaskId(route?.state?.currentData?.task_id)

        let payload = {
            taskId: route?.state?.currentData?.task_id
        }

        if (payload?.taskId?.length > 0) {
            dispatch(BulkUpload(payload));
        }

    }, [route])


    useEffect(() => {
        dispatch(PostDownloadBulkOrder())
    }, [])


    useEffect(() => {
        setTaskBulkId(PostBulkFileData?.task_id)
    }, [PostBulkFileData])


    useEffect(() => {
        let payload = {
            taskId: taskBulkId
        }
        const timeout = setTimeout(() => {
            if (taskBulkId == undefined) {
                // console.log("djasdga")
            } else {
                dispatch(BulkUpload(payload));
            }
        }, 2000)

        return () => clearTimeout(timeout)
    }, [taskBulkId]);

    // useEffect(()=>{
    //     let payload = {
    //         taskId: taskBulkId 
    //     }
    //  dispatch(BulkUpload(payload))
    // },[])

    // console.log("BulkUploadData",BulkUploadData)


    useEffect(() => {
        let payload = {
            taskId: taskBulkId ? taskBulkId : postTaskId
        }
        // console.log("sjaghdjas",taskBulkId, postTaskId )
        if (BulkUploadData) {
            if (BulkUploadData?.Status == "PROGRESS") {
                // console.log("entered inside PROGRESS");
                setPercentage(BulkUploadData?.current_step);
                setBulkDetailsData(BulkUploadData?.list);
                if (payload?.taskId?.length > 0) {
                    dispatch(BulkUpload(payload));
                }
                // dispatch(BulkUpload(payload));
            } else if (
                BulkUploadData?.result?.Status == "PAUSED"
            ) {
                // console.log("entered inside SUCCESS,PAUSED");
                setPercentage(BulkUploadData?.result?.current_step);
                setBulkDetailsData(BulkUploadData?.result?.error);
                setBulkDetailsData(BulkUploadData?.result?.list);
                // toast.error(BulkUploadData?.result?.message)
                if (payload?.taskId?.length > 0) {
                    dispatch(BulkUpload(payload));
                }
                // dispatch(BulkUpload(payload));
            } else if (BulkUploadData?.Status === "PENDING") {
                // toast.warn(BulkUploadData?.Status);
                setBulkDetailsData([]);
                setPercentage(0.1);
                if (payload?.taskId?.length > 0) {
                    dispatch(BulkUpload(payload));
                }
                // dispatch(BulkUpload(payload));
                // setTaskBulkId('')
            }
            // else if (BulkUploadData?.Status === "FAILURE") {
            //     // setBulkDetailsData([]);
            //     setBulkDetailsData(BulkUploadData);
            //     toast.error(BulkUploadData?.Status);
            //     setPercentage(0);
            //     // SetPostTaskId("")
            //     // setTaskBulkId("")
            //     // setTaskBulkId('')
            // }

            else if (BulkUploadData?.Status === "FAILURE") {
                try {
                    const parsedList = JSON.parse(BulkUploadData.list.replace(/'/g, '"')) || JSON.parse(BulkUploadData?.list);

                    setBulkDetailsData({ list: parsedList?.list || [parsedList?.message] || [] }); // Ensure 'list' is always an array
                } catch (error) {
                    console.error("Failed to parse BulkUploadData.list:", error);
                    setBulkDetailsData({ list: [] }); // Set an empty list on failure
                }

                toast.error(BulkUploadData?.Status);
                setPercentage(0); 
            }
            else if (BulkUploadData?.Status == "SUCCESS") {
                // console.log("entered inside SUCCESS");
                setPercentage(100);
                setBulkDetailsData(BulkUploadData?.result);
                // SetPostTaskId("")
                // setTaskBulkId("")
            } else {
                setPercentage(BulkUploadData?.result?.current_step);
                setBulkDetailsData(BulkUploadData?.result?.error);
                setBulkDetailsData(BulkUploadData?.result?.list);
                // toast.error(BulkUploadData?.result?.message)
                if (payload?.taskId?.length > 0) {
                    dispatch(BulkUpload(payload));
                }
                // console.log("Status failed");
            }
        }
    }, [BulkUploadData])

    const handleUploadFile = (event) => {

        setShowData("")

        let fileInput = event?.target?.files[0];

        let formdata = new FormData();
        formdata.append("user_id", routeData?.user_id);
        formdata.append("bulk_data", fileInput);

        dispatch(PostUploadBulkFiles(formdata));
        event.target.value = null;
    };


    const DownloadSample = () => {
        const url = PostDownloadBulkOrderData?.download_sample_file
    }

    const showValues = (itemId) => {

        setShowData((prevShowData) => ({
            ...prevShowData,
            [itemId]: !prevShowData[itemId],
        }));
    };

    const showMessage = (index) => {

        setMessage((prevShowData) => ({
            ...prevShowData,
            [index]: !prevShowData[index],
        }));
    };

    // 044dda4f-8267-4e67-ab2d-80325b463338

    const handleBackNavigation = (e) => {
        setBulkDetailsData("");
        setTaskBulkId("")
        navigate('/bulkuploadprofile');
        window.location.reload()

        // const timeout = setTimeout(() => {
        //     if(bulkDetailsData?.length > 0){
        //         console.log("kjshdas")
        //     }else{
        //         navigate('/bulkuploadprofile');
        //     }
        //   }, 2000)

        //   return () => clearTimeout(timeout)
    }

    // useEffect(()=>{
    //     if(bulkDetailsData == true){
    //         setBulkDetailsData("")
    //         navigate('/bulkuploadprofile');
    //         window.location.reload()
    //     }
    // },[bulkDetailsData])

    // console.log("BulkUploadData", BulkUploadData)
    // console.log("bulkDetailsData", bulkDetailsData)

    return (
        <>
            <div className={`${ToggleFunData ? "collapsemenu" : ""}`}>
                <Header />
                <div className={`dashboard-part `}>
                    <Sidebar />
                    <div className="content-sec userdetailpage">
                        <div className="title-bar">
                            <div className="left-part">
                                <h2>{routeData?.company_name}</h2>


                            </div>
                            <div className="centerpart">
                                <p className="text-danger"> <strong>Do Not Refresh This Page</strong></p>
                            </div>
                            <div className="right-part mt-2 mt-sm-0">

                                <button
                                    className="backbtn"
                                    type="button"
                                    onClick={(e) => handleBackNavigation(e)}
                                >
                                    Back
                                </button>

                            </div>
                        </div>

                        <div className="userdetailpart">
                            <div className="leftpart">
                                <div className="userdetailbox">
                                    <h3> Click to Upload File here </h3>
                                    <div className={(percentage > 0 && percentage < 100) ? "disableButton" : "uploadbtn"}>
                                        <input type="file" id="upload" onChange={(e) => handleUploadFile(e)} accept=".xlsx" disabled={(percentage > 0 && percentage < 100) && true} hidden />
                                        <label for="upload">Choose file</label>
                                    </div>

                                    <div className="mt-3">
                                        {/* <input type="file" id="upload" onChange={(e) => handleUploadFile(e)}/> */}
                                        <a href={PostDownloadBulkOrderData?.download_sample_file}>Download Sample</a>
                                    </div>
                                    <div className="datatxt">
                                        <p>Supported files: .excel</p>
                                        <p>upto 64mb</p>
                                        <p>Do Not Refresh the page!</p>

                                    </div>
                                </div>
                            </div>
                            <div className="rightpart">
                                <h2>File Name</h2>
                                <div className="uploadflierange">
                                    <div className="uploadimg">
                                        {((percentage == 100 || OrderPagesLoaderTrueFalseData == false) || (BulkUploadData?.Status == "PENDING || FAILURE"))
                                            ? (null)
                                            : (<div className="spinrotate">
                                                <img src={rotateimg} alt="rotate img" />
                                            </div>
                                            )}
                                        <img src={upload} alt="img" />
                                    </div>
                                    <div className="progressbox">
                                        <div className="progresstext">
                                            <h4>{percentage}%</h4>

                                            <button type="button" className="btn p-0 m-0">


                                                {/* {OrderPagesLoaderTrueFalseData == true ? <p><strong>Processing...</strong></p> : ""} */}
                                                {/* <img src={pause} alt="pause" /> */}
                                                {BulkUploadData?.Status == "PENDING" ? <p className="text-primary"><strong>Please wait your file is processing...</strong></p> : BulkUploadData?.result?.Status == "PAUSED" ? <p className="text-danger"><strong>Insufficient Balance</strong></p> : OrderPagesLoaderTrueFalseData == true ? <p className="text-primary"><strong>Processing...</strong></p> : BulkUploadData?.Status == "SUCCESS" ? <p className="text-success"><strong>Successful</strong></p> : ""}
                                                {/* { BulkUploadData?.result?.Status == "PAUSED" ? <p className="text-danger"><strong>Insufficient Balance</strong></p> : ""} */}
                                            </button>
                                        </div>


                                        <div class="progress" >
                                            <div class="progress-bar"
                                                style={{ width: `${percentage}%` }}
                                            >
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="datatable">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th> s.no. </th>
                                                <th> Data </th>
                                                <th> Status </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bulkDetailsData?.length > 0 ?
                                                bulkDetailsData?.map((item, index) => {

                                                    // console.log("ITEM==", item?.Row)
                                                    return (
                                                        <tr key={index}>
                                                            <td> Row {item?.Row} </td>

                                                            <td>
                                                                {item.Detail.slice(0, 80)}
                                                                <span
                                                                    onClick={() => showValues(index)}
                                                                    role="button"
                                                                    style={{
                                                                        color: "#faad14",
                                                                        fontWeight: "400",
                                                                        fontSize: "13px",
                                                                    }}
                                                                >
                                                                    {!showData[index] ? "..more" : "..less"}
                                                                </span>

                                                                <span
                                                                    className="order-btn text-primary"
                                                                    role="button"
                                                                >
                                                                    {showData[index] && (
                                                                        <div className="dropdown">
                                                                            <ul className=" address_all ">
                                                                                <li className="text-dark">
                                                                                    {item.Detail}
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    )}
                                                                </span>
                                                            </td>
                                                            <td key={index}>
                                                                {" "}
                                                                <button
                                                                    onMouseOver={() => showMessage(index)}
                                                                    onMouseOut={() => setMessage([])}
                                                                    type="button"
                                                                    className={
                                                                        item?.Message == "Successfully uploaded"
                                                                            ? "successbtn"
                                                                            : "errorbtn"
                                                                    }
                                                                >
                                                                    {" "}
                                                                    {item?.Message == "Successfully uploaded"
                                                                        ? "Success"
                                                                        : "Error"}{" "}
                                                                </button>{" "}
                                                                <span
                                                                    className="order-btn text-primary"
                                                                    role="button"
                                                                >
                                                                    {message[index] && (
                                                                        <div className="dropdown">
                                                                            <ul className=" address_all ">
                                                                                <li className="text-dark text-nowrap">
                                                                                    {item.Message || item.Error}
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    )}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    );
                                                }) : bulkDetailsData?.list?.length > 0 ? bulkDetailsData?.list?.map((item) => {
                                                    return <p className="displaytext">{item}</p>
                                                }) : <p className="displaytext">{"No Data to display"}</p>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <LodingSpiner loadspiner={OrderPagesLoaderTrueFalseData} /> */}
        </>
    );
};

export default UserDetail;
